
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component, Prop } from 'vue-property-decorator'
import ZoneCard from '@/components/collections/zones/ZoneCard.vue'
import AutoCompleter, { AutocompleteProvider } from '../../inputs/AutoCompleter.vue'
import ZoneAutocompleteResult from './ZoneAutocompleResult'

@Component({
  components: {
    AutoCompleter,
    ZoneCard
  }
})
export default class ZoneSelector extends Vue {
  // Results
  public searchTerm = ''
  public minTermLength = 3;

  @Prop({ default: 1 })
  private readonly maxSelections!: number;

  private zoneProvider: AutocompleteProvider<ZoneAutocompleteResult> = {
    Search: function (term: string): Promise<ZoneAutocompleteResult[]> {
      return (Services.API.Zones.Autocomplete(term)
        .then((returnModels) => {
          return returnModels.map((result) => {
            return {
              Label: result.TMName,
              Value: result.ZoneId,
              Zone: result
            } as ZoneAutocompleteResult
          }) as ZoneAutocompleteResult[]
        })
      )
    },
    Suggest: function (term: string): Promise<string|null> {
      console.log(term)
      throw new Error('Function not implemented.')
    }
  }

  @Prop({ default: () => [] })
  private readonly excludedZones!: ZoneModel[];

  // Selections
  @Prop({ required: true })
  private readonly value!: ZoneModel[];

  get canSelectMore (): boolean {
    if (this.maxSelections <= 0) return true
    return this.value.length < this.maxSelections
  }

  onResultSelected (selection: ZoneAutocompleteResult): void {
    const zone = selection.Zone
    const alreadyInZones = this.value.find((val) => val.ZoneId === selection.Zone.ZoneId)
    if (alreadyInZones !== undefined) {
      return
    }
    const inExcluded = this.excludedZones.find((val) => val.ZoneId === selection.Zone.ZoneId)
    if (inExcluded) {
      return
    }
    const newValue = [...this.value, zone]
    this.$emit('input', newValue)
  }

  removeZone (zoneId: string): void {
    if (!zoneId) return
    const index = this.value.findIndex((item) => item.ZoneId === zoneId)
    if (index === -1) {
      console.error('Zone index not found')
      return
    }
    const newValue = this.value
    newValue.splice(index, 1)
    this.$emit('input', newValue)
  }
}

