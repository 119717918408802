
import { Input } from '@/mixins'
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component
export default class InputCheckbox extends Mixins(Input) {
  @Prop({ required: true })
  private value!: boolean;

  @Prop({ default: '' })
  private readonly label!: string;

  mounted (): void {
    // Checked styling is broken when value is true on load. This fixes
    const input = this.$refs.checky as HTMLInputElement
    if (this.value === true) {
      input.checked = true
    }
  }
}
