
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class ZoneCard extends Vue {
  @Prop({ required: true })
  private readonly zone!: ZoneModel;
}

