
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class CategoryCard extends Vue {
  @Prop({ required: true })
  private readonly category!: CategoryModel;

  getFlattenedParents (): string {
    function flatten (cat: CategoryModel): CategoryModel[] {
      if (cat.Parent === null) return [cat]
      const arr = flatten(cat.Parent)
      arr.unshift(cat)
      return arr
    }

    const flattened = flatten(this.category).reverse()
    flattened.pop()
    const flattenedNames = flattened.map((cat) => cat.Name)
    const parentsJoined = flattenedNames.join(' > ')
    return parentsJoined
  }
}

