
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AutoCompleter, { AutocompleteProvider } from '@/components/inputs/AutoCompleter.vue'
import CategoryCard from './CategoryCard.vue'
import { AutocompleteResult } from '@/components/inputs/AutocompleteResult'

export interface CategoryAutocompleteResult extends AutocompleteResult {
  Category: CategoryModel;
  FlattenedParents: CategoryModel[];
}

interface CategoryAutocompleteProvider extends AutocompleteProvider<CategoryAutocompleteResult> {
  FlattenParents (model: CategoryModel): CategoryModel[];
}

@Component({
  components: {
    AutoCompleter,
    CategoryCard
  }
})
export default class CategorySelector extends Vue {
  // Results
  public searchTerm = ''
  public minTermLength = 3;
  private loading = false

  @Prop({ default: 1 })
  private readonly maxSelections!: number;

  private categoryProvider: CategoryAutocompleteProvider = {
    FlattenParents: function (model: CategoryModel): CategoryModel[] {
      function flatten (cat: CategoryModel): CategoryModel[] {
        if (cat.Parent === null) return [cat]
        const arr = flatten(cat.Parent)
        arr.unshift(cat)
        return arr
      }
      const flattened = flatten(model).reverse()
      flattened.pop()
      return flattened
    },
    Search: function (term: string): Promise<CategoryAutocompleteResult[]> {
      return (Services.API.Categories.AutocompleteCategoryiesOnly(term)
        .then((returnModels) => {
          return returnModels.map((result) => {
            return {
              Label: result.Name,
              Value: result.CategoryId,
              Category: result,
              FlattenedParents: this.FlattenParents(result)
            } as CategoryAutocompleteResult
          }) as CategoryAutocompleteResult[]
        })
      )
    },
    Suggest: function (term: string): Promise<string|null> {
      console.log(term)
      throw new Error('Function not implemented.')
    }
  }

  get canSelectMore (): boolean {
    if (this.maxSelections <= 0) return true
    return this.value.length < this.maxSelections
  }

  // Selections
  // v-model a category model array
  @Prop({ required: true })
  private readonly value!: CategoryModel[];

  onResultSelected (selection: CategoryAutocompleteResult): void {
    const category = selection.Category
    const alreadyInCategories = this.value.find((val) => val.CategoryId === selection.Category.CategoryId)
    if (alreadyInCategories !== undefined) {
      return
    }
    const newValue = [...this.value, category]
    this.$emit('input', newValue)
  }

  removeCategory (categoryId: string): void {
    if (!categoryId) return
    const index = this.value.findIndex((item) => item.CategoryId === categoryId)
    if (index === -1) {
      console.error('Category index not found')
      return
    }
    const newValue = this.value
    newValue.splice(index, 1)
    this.$emit('input', newValue)
  }
}
