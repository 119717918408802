
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Input } from '@/mixins'

@Component({
  components: {
  }
})

export default class InputNumber extends Mixins(Input) {
  // v-model.number used on component will bind to this value
  @Prop({ required: true })
  private readonly value!: number;

  @Prop({ default: '' })
  private readonly min!: string

  @Prop({ default: '' })
  private readonly max!: string

  @Prop({ default: '' })
  private readonly step!: string
}

