
import { Input } from '@/mixins'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import { Component, Prop, Mixins } from 'vue-property-decorator'

@Component
export default class InputSelect extends Mixins(Input) {
  // v-model this
  @Prop({ default: '' })
  public value!: number | string | Record<string, unknown>;

  @Prop({ required: true })
  private readonly items!: DropdownListItem[];

  get hasValue (): boolean {
    if (this.value === null) return false
    if (this.value === '') return false
    return true
  }
}
