
import { Component, Prop, Mixins } from 'vue-property-decorator'
import { Input } from '@/mixins'

@Component({
  components: {
  }
})

export default class InputTextarea extends Mixins(Input) {
  // v-model on this to access this
  @Prop({ required: true })
  private readonly value!: string;

  @Prop({ default: 32000 })
  private readonly maxLength!: number

  @Prop({ default: 4 })
  private readonly rows!: number;

  mounted (): void {
    this.$nextTick(() => {
      this.setHeight()
    })
  }

  setHeight (): void {
    const textarea = this.$refs.textarea as HTMLTextAreaElement
    textarea.style.height = 'auto'
    textarea.style.height = `${textarea.scrollHeight}px`
  }
}

